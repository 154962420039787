import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import NotFound from '../views/NotFound.vue'

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

const routes = [
    { path: '/', name: 'Login', component: Login },
    { path: '/test', name: 'Test', component: () => import('@/views/test/Index.vue') },
    {
        path: '', name: 'Admin', component: () => import('@/layouts/AdminBasics'),
        children: [
            /*************模板************/
            { path: '/template/list', name: '列表', component: () => import('@/views/template/List') },
            { path: '/template/form', name: '表单', component: () => import('@/views/template/Form') },
            { path: '/template/test', name: '测试', component: () => import('@/views/template/Test') },
            { path: '/template/echarts', name: '数据统计图', component: () => import('@/views/template/Echarts') },
            { path: '/template/cropperImage', name: '截图', component: () => import('@/views/template/CropperImage') },
            { path: '/template/tailoring', name: '页面', component: () => import('@/views/template/Tailoring') },
            /*************模板 end************/
            /*************IT管理的应用权限************/
            { path: '/itset/osscontrol', name: 'oss系统菜单', component: () => import('@/views/itset/Osscontrol') },
            { path: '/itset/companycontrol', name: '商户系统菜单', component: () => import('@/views/itset/Companycontrol') },
            { path: '/itset/clientapi', name: '客户端API', component: () => import('@/views/itset/Clientapi') },
            { path: '/itset/coachapi', name: '教练端API', component: () => import('@/views/itset/Coachapi') },
            { path: '/itset/wxmerchant', name: '商户端小程序API', component: () => import('@/views/itset/WxMerchantapi') },
            { path: '/itset/materialimg', name: '图片素材', component: () => import('@/views/itset/Materialimg') },
            /*************IT管理的应用权限 end************/
            /*************主页基础************/
            { path: '/home', name: 'home', component: () => import('@/views/home/Home') },
            { path: '/home/password', name: '修改密码', component: () => import('@/views/home/Password') },
            /*************主页基础 end************/
            /*************注册协议************/
            { path: '/agreement/company', name: '公司', component: () => import('@/views/agreement/Company') },
            { path: '/agreement/client', name: '客户', component: () => import('@/views/agreement/Client') },
            { path: '/agreement/coach', name: '教练', component: () => import('@/views/agreement/Coach') },
            { path: '/agreement/consumer', name: '消费卡协议', component: () => import('@/views/agreement/Consumer') },
            { path: '/agreement/groupbuy', name: '拼团协议', component: () => import('@/views/agreement/Groupbuy') },
            /*************注册协议 end************/
            /*************系统设置************/
            { path: '/systemset/role', name: '角色管理', component: () => import('@/views/systemset/role/List') },
            { path: '/systemset/role/see', name: '角色详情', component: () => import('@/views/systemset/role/Details') },
            { path: '/systemset/user', name: '系统用户', component: () => import('@/views/systemset/user/List') },
            { path: '/systemset/user/see', name: '用户详情', component: () => import('@/views/systemset/user/Details') },
            { path: '/systemset/log', name: '操作日志', component: () => import('@/views/systemset/log/List') },
            { path: '/systemset/cityset', name: '城市设置', component: () => import('@/views/systemset/cityset/Info') },
            { path: '/systemset/feedback', name: '问题反馈', component: () => import('@/views/systemset/feedback/List') },
            { path: '/systemset/feedback/detail', name: '问题反馈', component: () => import('@/views/systemset/feedback/Detail') },
            { path: '/systemset/merchantFeedback', name: '商户端问题反馈', component: () => import('@/views/systemset/merchantFeedback/List') },
            { path: '/systemset/merchantFeedback/detail', name: '商户端问题反馈详情', component: () => import('@/views/systemset/merchantFeedback/Detail') },

            /*************系统设置 end************/
            /*************商户管理************/
            { path: '/company/notreviewedcompany', name: '未审核企业', component: () => import('@/views/company/company/Notreviewedcompany') },
            { path: '/company/newcompany', name: '新注册企业', component: () => import('@/views/company/company/Newcompany') },
            { path: '/company/company', name: '企业列表', component: () => import('@/views/company/company/List'), meta: { keepAlive: true } },
            { path: '/company/company/see', name: '企业详情', component: () => import('@/views/company/company/Details') },
            { path: '/company/category', name: '商户类别', component: () => import('@/views/company/category/List') },
            // {path: '/company/companytype', name: '企业类型', component: () => import('@/views/company/company/Companytype')},
            /*************商户管理 end************/
            /*************门店管理************/
            { path: '/store/store', name: '门店列表', component: () => import('@/views/store/store/List'), meta: { keepAlive: true } },
            { path: '/store/approvalstore', name: '待审批门店', component: () => import('@/views/store/approvalstore/List') },
            { path: '/store/store/add', name: '门店添加', component: () => import('@/views/store/store/Add') },
            { path: '/store/store/details', name: '门店详情', component: () => import('@/views/store/store/Details') },
            { path: '/store/storetype', name: '门店类型', component: () => import('@/views/store/Storetype') },
            { path: '/store/tag', name: '门店标签', component: () => import('@/views/store/tag/List') },
            /*************门店管理 end************/
            /*************课程管理 ************/
            { path: '/lesson/lesson', name: '课程列表', component: () => import('@/views/lesson/lesson/List') },
            { path: '/lesson/approvallesson', name: '待审核课程', component: () => import('@/views/lesson/approvalstore/List') },
            { path: '/lesson/lesson/add', name: '课程添加', component: () => import('@/views/lesson/lesson/Add') },
            { path: '/lesson/lesson/details', name: '课程详情', component: () => import('@/views/lesson/lesson/Details') },
            { path: '/lesson/tag', name: '课程标签', component: () => import('@/views/lesson/tag/List') },

            { path: '/product/activity', name: '活动列表', component: () => import('@/views/product/activity/List') },
            { path: '/product/activity/details', name: '活动详情', component: () => import('@/views/product/activity/Details') },

            // 商品管理
            { path: '/product/commodity', name: '商品', component: () => import('@/views/product/commodity/List') },
            { path: '/product/commodity/details', name: '商品添加', component: () => import('@/views/product/commodity/Details') },
            { path: '/product/commodityorder', name: '商品订单', component: () => import('@/views/product/commodityorder/List') },
            // 赛事管理
            { path: '/product/contest', name: '赛事列表列表', component: () => import('@/views/product/contest/List') },
            { path: '/product/contest/details', name: '赛事详情', component: () => import('@/views/product/contest/Details') },
            // 课程模板
            { path: '/lesson/lessontemplate', name: '课程模板列表', component: () => import('@/views/lesson/lessontemplate/List') },
            { path: '/lesson/lessontemplate/add', name: '课程模板添加', component: () => import('@/views/lesson/lessontemplate/Add') },
            { path: '/lesson/lessontemplate/details', name: '课程模板详情', component: () => import('@/views/lesson/lessontemplate/Details') },
            /*************课程管理end************/
            /*************运动票 ************/
            { path: '/product/approvalsportsticket', name: '待审核运动票', component: () => import('@/views/product/approvalsportsticket/List') },
            // {path: '/product/approvalsportsticket/add', name: '课程添加', component: () => import('@/views/product/approvalsportsticket/Add')},
            { path: '/product/sportsticket/details', name: '运动票详情', component: () => import('@/views/product/sportsticket/Details') },
            /*************运动票end************/
            /*************用户管理 ************/
            { path: '/user/searchlog', name: '用户搜索关键字', component: () => import('@/views/user/searchlog/List') },
            { path: '/user/searchlognew', name: '用户搜索关键字(新)', component: () => import('@/views/user/searchlog/Listnew') },
            { path: '/user/wxuser', name: '微信用户', component: () => import('@/views/user/wxuser/List') },
            { path: '/user/member', name: '普通会员', component: () => import('@/views/user/member/List') },
            { path: '/user/member/info', name: '普通会员信息', component: () => import('@/views/user/member/Info') },
            { path: '/user/fictitious', name: '虚拟用户', component: () => import('@/views/user/fictitious/List') },
            { path: '/user/fictitious/add', name: '添加虚拟用户', component: () => import('@/views/user/fictitious/Add') },
            { path: '/user/fictitious/batchAdd', name: '批量添加虚拟用户', component: () => import('@/views/user/fictitious/BatchAdd') },

            { path: '/user/memberpull', name: '拉新排行', component: () => import('@/views/user/memberpull/List') },
            /*************用户管理 end************/
            /*************教练管理************/
            { path: '/coach/joinapply', name: '待加入', component: () => import('@/views/coach/joinapply/List') },
            { path: '/coach/coachapply', name: '教练审核', component: () => import('@/views/coach/coachapply/List') },
            { path: '/coach/coachapply/details', name: '教练审核详情', component: () => import('@/views/coach/coachapply/Details') },
            { path: '/coach/companycoach', name: '我的教练', component: () => import('@/views/coach/companycoach/List') },
            { path: '/coach/companycoach/details', name: '我的教练详情', component: () => import('@/views/coach/companycoach/Details') },
            { path: '/coach/coachpool', name: '教练列表', component: () => import('@/views/coach/coachpool/List') },
            { path: '/coach/coachpool/details', name: '教练详情', component: () => import('@/views/coach/coachpool/Details') },
            { path: '/coach/tag', name: '教练标签', component: () => import('@/views/coach/tag/List') },
            { path: '/coach/rulesgroup', name: '团课佣金规则', component: () => import('@/views/coach/rulesgroup/List') },
            { path: '/coach/rulescamp', name: '训练营佣金规则', component: () => import('@/views/coach/rulescamp/List') },
            { path: '/coach/rulespt', name: '私教佣金规则', component: () => import('@/views/coach/rulespt/List') },
            /*************教练管理 end************/
            /*************公众号管理************/
            { path: '/mpwx/user', name: '公众号用户', component: () => import('@/views/mpwx/User') },
            { path: '/mpwx/templatemsgsend', name: '模板消息发送', component: () => import('@/views/mpwx/Templatemsgsend') },
            { path: '/mpwx/templatemsglist', name: '模板消息列表', component: () => import('@/views/mpwx/Templatemsglist') },
            { path: '/mpwx/bottommenu', name: '底部菜单', component: () => import('@/views/mpwx/Bottommenu') },
            { path: '/mpwx/keyword', name: '关键字管理', component: () => import('@/views/mpwx/Keyword') },
            { path: '/mpwx/subscribereply', name: '关注回复', component: () => import('@/views/mpwx/Subscribereply') },
            { path: '/mpwx/material', name: '素材管理', component: () => import('@/views/mpwx/Material') },
            { path: '/mpwx/wxcontent', name: '图文管理', component: () => import('@/views/mpwx/wxcontent/List') },
            { path: '/mpwx/wxcontent/info', name: '图文详情', component: () => import('@/views/mpwx/wxcontent/Info') },
            /*************公众号管理 end************/
            /*************课表管理************/
            { path: '/lessonschedule/lessonfastarrange', name: '快速排课', component: () => import('@/views/lessonschedule/lessonarrange/Fastarrange') },
            { path: '/lessonschedule/groupteaching', name: '团课管理', component: () => import('@/views/lessonschedule/groupteaching/List') },
            { path: '/lessonschedule/groupteaching/edit', name: '团课编辑', component: () => import('@/views/lessonschedule/groupteaching/Edit') },
            { path: '/lessonschedule/groupteaching/check', name: '团课查看', component: () => import('@/views/lessonschedule/groupteaching/Check') },
            { path: '/lessonschedule/camp', name: '训练营管理', component: () => import('@/views/lessonschedule/camp/List') },
            { path: '/lessonschedule/camp/edit', name: '训练营编辑', component: () => import('@/views/lessonschedule/camp/Edit') },
            { path: '/lessonschedule/camp/check', name: '训练营编辑', component: () => import('@/views/lessonschedule/camp/Check') },
            { path: '/lessonschedule/pt', name: '私教管理', component: () => import('@/views/lessonschedule/pt/List') },
            { path: '/lessonschedule/pt/edit', name: '私教编辑', component: () => import('@/views/lessonschedule/pt/Edit') },
            { path: '/lessonschedule/pt/check', name: '私教编辑', component: () => import('@/views/lessonschedule/pt/Check') },
            { path: '/lessonschedule/ptsku', name: '私教规格', component: () => import('@/views/lessonschedule/ptsku/Info') },
            /*************课表管理 end************/
            /*************订单管理************/
            { path: '/order/activity', name: '活动订单', component: () => import('@/views/order/activity/List') },
            { path: '/order/group', name: '团课订单', component: () => import('@/views/order/group/List') },
            { path: '/order/camp', name: '训练营订单', component: () => import('@/views/order/camp/List') },
            { path: '/order/pt', name: '私教订单', component: () => import('@/views/order/pt/List') },
            { path: '/order/contest', name: '赛事订单', component: () => import('@/views/order/contest/List') },
            { path: '/order/all', name: '全部订单', component: () => import('@/views/order/all/List') },
            /*************订单管理 end************/
            /*************账本管理************/
            { path: '/accountbook/monthly', name: '账本管理', component: () => import('@/views/accountbook/Monthly') },
            { path: '/accountbook/withdrawal', name: '提现列表', component: () => import('@/views/accountbook/Withdrawal') },
            { path: '/accountbook/withdrawal2', name: '提现列表2', component: () => import('@/views/accountbook/Withdrawal2') },
            /*************账本管理 end************/
            /*************报表统计************/
            { path: '/reportforms/platformdata', name: '报表管理', component: () => import('@/views/reportforms/Platformdata') },
            { path: '/reportforms/platformdata/chartdata', name: '查看图表', component: () => import('@/views/reportforms/Chartdata') },
            /*************报表统计 end************/
            /*************优惠券************/
            { path: '/coupon/library', name: '优惠券', component: () => import('@/views/coupon/library/List') },
            { path: '/coupon/library/add', name: '添加优惠券', component: () => import('@/views/coupon/library/Add') },
            { path: '/coupon/library/info', name: '优惠券详情', component: () => import('@/views/coupon/library/Info') },

            { path: '/coupon/bale', name: '券包', component: () => import('@/views/coupon/bale/List') },
            { path: '/coupon/bale/add', name: '添加券包', component: () => import('@/views/coupon/bale/Add') },
            { path: '/coupon/bale/info', name: '券包详情', component: () => import('@/views/coupon/bale/Info') },

            { path: '/coupon/activity', name: '活动券', component: () => import('@/views/coupon/activity/List') },
            { path: '/coupon/activity/add', name: '添加活动券', component: () => import('@/views/coupon/activity/Add') },
            { path: '/coupon/activity/info', name: '活动券详情', component: () => import('@/views/coupon/activity/Info') },

            { path: '/coupon/scoreexchange', name: '积分兑换券', component: () => import('@/views/coupon/scoreexchange/List') },
            { path: '/coupon/scoreexchange/add', name: '添加积分兑换券', component: () => import('@/views/coupon/scoreexchange/Add') },
            { path: '/coupon/scoreexchange/info', name: '积分兑换券详情', component: () => import('@/views/coupon/scoreexchange/Info') },

            { path: '/coupon/shareset', name: '分享有礼优惠券设置', component: () => import('@/views/coupon/shareset/Edit') },
            { path: '/coupon/newuserset', name: '新用户优惠券设置', component: () => import('@/views/coupon/newuserset/Edit') },


            /*************优惠券 end************/
            /*************评价************/
            { path: '/comment/comment', name: '评价列表', component: () => import('@/views/comment/List') },
            { path: '/comment/wellcomment', name: '评价精选列表', component: () => import('@/views/comment/wellcomment/List') },
            /*************评价 end************/
            /*************主页设置************/
            { path: '/weapphome/classification', name: '主页分类', component: () => import('@/views/weapphome/classification/List') },
            { path: '/weapphome/classification/add', name: '添加主页分类', component: () => import('@/views/weapphome/classification/Add') },
            // {path: '/weapphome/classification/details', name: '主页分类详情', component: () => import('@/views/weapphome/classification/Details')},
            { path: '/weapphome/like', name: '喜好', component: () => import('@/views/weapphome/like/List') },
            { path: '/weapphome/like/add', name: '添加喜好', component: () => import('@/views/weapphome/like/Add') },
            { path: '/weapphome/keyword', name: '默认搜索关键字', component: () => import('@/views/weapphome/keyword/List') },
            { path: '/weapphome/background', name: '页面背景图', component: () => import('@/views/weapphome/background/Info') },

            { path: '/weapphome/banner', name: '页面背景图列表', component: () => import('@/views/weapphome/banner/List') },
            { path: '/weapphome/notification', name: '弹窗管理', component: () => import('@/views/weapphome/notification/List') },
            { path: '/weapphome/label', name: '运营标签', component: () => import('@/views/weapphome/label/List') },
            { path: '/weapphome/hotActivity', name: '热门活动列表', component: () => import('@/views/weapphome/hotActivity/List') },
            { path: '/weapphome/hotActivity/edit', name: '热门活动列表编辑', component: () => import('@/views/weapphome/hotActivity/Edit') },

            { path: '/weapphome/category', name: '品类管理列表', component: () => import('@/views/weapphome/category/List') },
            { path: '/weapphome/category/add', name: '品类管理添加', component: () => import('@/views/weapphome/category/Add') },

            // 抽奖转盘活动
            { path: '/weapphome/lottery', name: '抽奖转盘活动列表', component: () => import('@/views/weapphome/lottery/List') },
            { path: '/weapphome/lottery/edit', name: '抽奖转盘活动列表', component: () => import('@/views/weapphome/lottery/Edit') },
            { path: '/weapphome/lottery/details', name: '抽奖转盘活动列表', component: () => import('@/views/weapphome/lottery/Info') },

            /*************主页设置 end************/

            /*************笔记管理************/
            { path: '/note/topic', name: '笔记话题', component: () => import('@/views/note/topic') },
            { path: '/note/monthvisits', name: '笔记月访问量', component: () => import('@/views/note/monthvisits') },
            { path: '/note/note', name: '话题列表', component: () => import('@/views/note/note') },
            { path: '/note/add', name: '虚拟笔记添加', component: () => import('@/views/note/Add') },
            { path: '/note/operate', name: '笔记操作回复', component: () => import('@/views/note/Operate') },
            /*************笔记管理 end************/

            /*************认证管理************/
            { path: '/approve/talent', name: '达人认证', component: () => import('@/views/approve/talent/List') },
            { path: '/approve/talent/details', name: '达人认证详情', component: () => import('@/views/approve/talent/Details') },
            { path: '/approve/coach', name: '教练认证', component: () => import('@/views/approve/coach/List') },
            { path: '/approve/coach/details', name: '教练认证详情', component: () => import('@/views/approve/coach/Details') },
            { path: '/approve/brand', name: '品牌认证', component: () => import('@/views/approve/brand/List') },
            { path: '/approve/brand/details', name: '品牌认证详情', component: () => import('@/views/approve/brand/Details') },
            /*************认证管理 end************/

            /*************消费卡管理************/
            { path: '/consumercard/card', name: '消费卡列表', component: () => import('@/views/consumercard/card/List') },
            { path: '/consumercard/card/edit', name: '消费卡编辑', component: () => import('@/views/consumercard/card/Edit') },
            { path: '/consumercard/receive', name: '消费卡领取', component: () => import('@/views/consumercard/receive/List') },
            { path: '/consumercard/company', name: '消费卡商户', component: () => import('@/views/consumercard/company/List') },
            /*************消费卡管理 end************/

            /*************拼团活动管理************/
            { path: '/marketing/groupbuycompany', name: '拼团商户', component: () => import('@/views/marketing/Groupbuycompany') },
            { path: '/marketing/groupbuy', name: '拼团活动', component: () => import('@/views/marketing/Groupbuy') },
            { path: '/marketing/groupbuy/initiatelist', name: '拼团记录', component: () => import('@/views/marketing/Initiatelist') },
            { path: '/marketing/groupbuy/detail', name: '拼团详情', component: () => import('@/views/marketing/GroupBuyDetail') },
            /*************拼团活动管理 end************/

            /*************用户召回管理************/
            { path: '/recall/analysis', name: '用户行为分析', component: () => import('@/views/recall/analysis/List') },
            { path: '/recall/smsTemplate', name: '短信模板', component: () => import('@/views/recall/smsTemplate/List') },
            { path: '/recall/wxTemplate', name: '小程序消息模板', component: () => import('@/views/recall/wxTemplate/List') },
            /*************用户召回管理************/
        ]
    },
    { path: '*', name: 'NotFound', component: NotFound }

]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
