/**
 * 从 VUE_APP_API_HOST 中提取 API 主机地址（带协议）
 *
 * @param {string} url 地址
 */
function extractAPIHost(url) {
    let host = new URL(url).origin;

    if (host.endsWith('/')) {
        host = host.substring(0, host.length - 1);
    }

    return host;
}


// 本地权限列表
let env = {
    // ICP号
    ICP: '沪ICP备20009731号',
    // 开发模式-默认否
    DE_BUG: process.env.VUE_APP_DE_BUG ?? 'false',
    // 接口地址
    API_URL: extractAPIHost(process.env.VUE_APP_API_HOST) ?? 'https://api.gymmartsports.com',
    // 分页条数
    pageSize: 10,
    //label默认宽度
    label_width: '120px',
    search_label_width: '100px',
    // message默认提示时间毫秒
    message_duration: 1000,
    message_error: 2000,
    gaodekey: 'feb8084f1d5d61ebcf052000f179061a',
}
export default env
