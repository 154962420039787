import axios from "axios";
import env from '@/../config/env'
import tool from "../tool";
import { Message } from 'element-ui';
// import help from "./../help";

const API_URL = env.API_URL

const instance = axios.create({
    baseURL: `${API_URL}/api/oss/`,
    timeout: 10000,
});

/**
 * 请求拦截器
 */
instance.interceptors.request.use(config => {
    console.log(config);
    const token = tool.get_l_cache('token') ?? '';
    const header = {};
    if (token == '') {
        // 跳转到登录页面
        console.log('请先登录')
        Message.error({
            message: '请先登录',
            onClose: () => {
                location.href = '/'
            }
        });
        return
    } else {
        header['token'] = token;
    }
    if (config.method === 'post') {
        config.data = {
            ...config.data,
            ...header
        }
    } else {
        config.params = {
            ...config.params,
            ...header
        }
    }
    // config['headers'] = {
    //     ...header
    // }
    return config;
})
/**
 * 相应拦截器
 */
instance.interceptors.response.use(response => {
    if (response.status === 200) {
        return response.data;
    }
    return response;
})

/**
 * GET请求
 * @param {string} url 请求地址
 * @param {object} data 请求参数
 * @returns 
 */
const get = (url, data) => {
    return new Promise((resolve, reject) => {
        instance
            .get(url, { params: data })
            .then((res) => {
                resolve(res);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

/**
 * POST请求
 * @param {string} url 请求地址
 * @param {object} data 请求参数
 * @returns 
 */
const post = (url, data) => {
    return new Promise((resolve, reject) => {
        instance
            .post(url, data)
            .then((res) => {
                resolve(res);
            })
            .catch((err) => {
                reject(err);
            });
    });
};
export { get, post };